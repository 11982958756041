import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M15.923 166.957C5.633 194.691 0 224.686 0 256s5.633 61.311 15.923 89.043L256 367.304l240.077-22.261C506.367 317.311 512 287.314 512 256s-5.633-61.309-15.923-89.043L256 144.696z",
      style: {"fill":"#f0f0f0"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M256 0C145.93 0 52.094 69.472 15.924 166.957h480.155C459.906 69.472 366.072 0 256 0",
      style: {"fill":"#d80027"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M256 512c110.072 0 203.906-69.472 240.078-166.957H15.924C52.094 442.528 145.93 512 256 512",
      style: {"fill":"#6da544"}
    }, null, -1)
  ])))
}
export default { render: render }